import { validarCBU } from '@helpers/cbuValidation';
import cardValidator from '@helpers/cardValidator';
import { createFilter } from '@helpers/filtersHelper';
import { saveGenericEntity, useGet } from '@service/AxiosBaseService';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { computed, ref } from 'vue';

export const rango_cliente_perdido = ref(null);

const rules = computed(() => ({
	documents: {
		$each: helpers.forEach({
			numero: {
				required(_, document) {
					return document._destroy === 1 || document.tipo_documento;
				},
			},
		}),
	},
	bank_accounts: {
		$each: helpers.forEach({
			numero: {
				required(value, account) {
					return account._destroy === 1 || validarCBU(value);
				},
			},
		}),
	},
	credit_cards: {
		$each: helpers.forEach({
			numero: {
				required(value, card) {
					return card._destroy === 1 || cardValidator(value);
				},
			},
		}),
	},
}));

export const filters = ref({
	nombre: { property: 'nombre', value: null, operator: 'like', type: 'string', label: 'Nombre' },
	documento: {
		property: 'numero_documento',
		value: null,
		operator: 'like',
		type: 'string',
		label: 'Documento',
	},
	contacto: { property: 'contacto', value: null, operator: 'like', type: 'string', label: 'Contacto' },
	people_group: { property: 'people_group', value: null, operator: 'in', type: 'combo', label: 'Grupo' },
	tiene_ramos: {
		property: 'ramo_ids',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Tiene Pólizas de los ramos',
	},
	no_tiene_ramos: {
		property: 'ramo_ids',
		value: null,
		operator: 'notin',
		type: 'combo',
		label: 'No tiene Pólizas de los ramos',
	},
	potencial_captivo: {
		property: 'estado_cliente',
		value: null,
		operator: 'eq',
		type: 'combo',
		label: 'Potencial / Captivo',
	},
	tomador_asegurado: {
		property: 'tipo_cliente',
		value: null,
		operator: 'eq',
		type: 'combo',
		label: 'Tomador / Asegurado',
	},
	vencimiento_registro_conducir_gte: {
		property: 'vencimiento_registro_conducir',
		value: null,
		operator: 'gte',
		type: 'date',
		label: 'Venc. Registro >',
	},
	vencimiento_registro_conducir_lte: {
		property: 'vencimiento_registro_conducir',
		value: null,
		operator: 'lte',
		type: 'date',
		label: 'Venc. Registro <',
	},
	intermediarios: {
		property: 'employee_id',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Intermediarios',
	},
	birthday_gte: { property: 'cumpleanos', value: null, operator: 'gte', type: 'date', label: 'Cumpleaños >' },
	birthday_lte: { property: 'cumpleanos', value: null, operator: 'lte', type: 'date', label: 'Cumpleaños <' },
	tipo_persona: {
		property: 'tipo_persona',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Tipo de Persona',
	},
	created_at_gte: {
		property: 'created_at',
		value: null,
		operator: 'gte',
		type: 'date',
		label: 'Fecha creación desde',
	},
	created_at_lte: {
		property: 'created_at',
		value: null,
		operator: 'lte',
		type: 'date',
		label: 'Fecha creación hasta',
	},
	last_active_policy_date_gte: {
		property: 'last_active_policy_date',
		value: null,
		operator: 'gte',
		type: 'date',
		label: 'Fecha venc. última poliza posterior a',
	},
	last_active_policy_date_lte: {
		property: 'last_active_policy_date',
		value: null,
		operator: 'lte',
		type: 'date',
		label: 'Fecha venc. última poliza anterior a',
	},
    region_id: {
		property: 'region_id',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Región',
	},
    segment_id: {
		property: 'segment_id',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Segmento',
	},
    sub_segment_id: {
		property: 'sub_segment_id',
		value: null,
		operator: 'in',
		type: 'combo',
		label: 'Subsegmento',
	},
});

export const defaultFilter = {
	nombre: createFilter('nombre', 'string', 'like', 'name'),
};

export function createEmptyClient() {
	return new Object({
		// Generales
		id: null,
		send_mail_notifications: null,
		nombre: null,
		estado_cliente: null,
		referido_por_id: null,
		created_at: null,
		tipo_condicion_iva: null,
		// Lo inicializo en "fisica" para que el Dropdown de AddEditClient arranque así y porque vi que también es el comportamiento del adminse viejo.
		tipo_persona: 'fisica',
		employee_ids: null,
		nota: null,
		// Fisica
		tipo_estado_civil: null,
		obra_social: null,
		estatura: null,
		fecha_nacimiento: null,
		peso: null,
		tipo_profesion: null,
		pais_nacimiento: null,
		numero_registro_conducir: null,
		vencimiento_registro_conducir: null,
		// Juridica
		tipo_tamano_empresa: null,
		capitas: null,
		masa_salarial: null,
		ciiu: null,
		ciiu_descripcion: null,
		ciiu_tabla: null,
		pep: null,
		sujeto_obligado: null,
		// Readonlys
		// tickets_abiertos,
		// active_policies_count,
		// siniestros_pendientes,
		// company_balance,
		// balance,
		// attachments_count,
		// ramos

		// Related
		documents: [],
		people_group_memberships: [],
		employees: [],
		bank_accounts: [],
		phones: [],
		emails: [],
		credit_cards: [],
		addresses: [],
		contacts: [],
	});
}

const ramosIcons = [
	{ shortname: 'Incendio', codigo: '30', pi_icon: 'la la-fire' },
	{ shortname: 'Automotores', codigo: '32', pi_icon: 'pi pi-car' },
	{ shortname: 'Combinados e Integrales', codigo: '31', pi_icon: 'pi pi-home' },
	{ shortname: 'Cristales', codigo: '33', pi_icon: 'pi pi-microsoft' },
	{ shortname: 'Granizo', codigo: '34', pi_icon: 'la la-snowflake' },
	{ shortname: 'Responsabilidad Civil', codigo: '36', pi_icon: 'la la-balance-scale' },
	{ shortname: 'Robo', codigo: '37', pi_icon: 'pi pi-shield' },
	{ shortname: 'Accidentes Personales', codigo: '38', pi_icon: 'la la-ambulance' },
	{ shortname: 'Caucion', codigo: '39', pi_icon: 'la la-gavel' },
	{ shortname: 'Crédito', codigo: '40', pi_icon: 'pi pi-credit-card' },
	{ shortname: 'Accidentes a Pasajeros', codigo: '41', pi_icon: 'la la-life-ring' },
	{ shortname: 'Aeronavegacion', codigo: '42', pi_icon: 'pi pi-send' },
	{ shortname: 'Riesgos Varios', codigo: '43', pi_icon: 'pi pi-sitemap' },
	{ shortname: 'Seguro Técnico', codigo: '44', pi_icon: 'la la-wrench' },
	{ shortname: 'Cascos', codigo: '46', pi_icon: 'la la-ship' },
	{ shortname: 'Sepelio', codigo: '49', pi_icon: 'pi pi-user-minus' },
	{ shortname: 'Retiro', codigo: '51', pi_icon: 'pi pi-dollar' },
	{ shortname: 'Retiro', codigo: '52', pi_icon: 'pi pi-dollar' },
	{ shortname: 'Salud', codigo: '53', pi_icon: 'pi pi-heart' },
	{ shortname: 'Transporte', codigo: '54', pi_icon: 'la la-bus' },
	{ shortname: 'Transporte', codigo: '45', pi_icon: 'la la-bus' },
	{ shortname: 'ART', codigo: '59', pi_icon: 'la la-medkit' },
	{ shortname: 'Motovehículos', codigo: '60', pi_icon: 'la la-motorcycle' },
	{ shortname: 'Vida', codigo: '47', pi_icon: 'pi pi-heart-fill' },
	{ shortname: 'Vida', codigo: '48', pi_icon: 'pi pi-heart-fill' },
	{ shortname: 'Vida', codigo: '55', pi_icon: 'pi pi-heart-fill' },
	{ shortname: 'Vida', codigo: '56', pi_icon: 'pi pi-heart-fill' },
	{ shortname: 'Vida', codigo: '57', pi_icon: 'pi pi-heart-fill' },
	{ shortname: 'Vida', codigo: '58', pi_icon: 'pi pi-heart-fill' },
];

export function getRamoIcon(ramo) {
	let ramoIcon = ramosIcons.find((x) => x.codigo === ramo.codigo);
	return ramoIcon ?? '';
}

export const resourceName = 'api/people';
export const resultKey = 'person';

// Saco todo esto al service porque las voy a usar desde otro lados seguramente
export const clientErrors = ref({});
export const selectedClient = ref(null);
export const addFormVisible = ref(false);

export const v$ = useVuelidate(rules, selectedClient);

export async function saveClient(client) {
	// hago una copia del client asi le puedo sacar las associations vacias pre submit
	let personCopy = JSON.parse(JSON.stringify(client.value));
	personCopy = cleanEmptyAssociations(personCopy);
	personCopy = setSimpleAssociations(personCopy);
	return await saveGenericEntity(client, { person: personCopy }, resourceName, resultKey);
}

export async function loadClient(id) {
	const person = await useGet(`${resourceName}/${id}`);
	selectedClient.value = person.data ? person.data.person : null;
	return selectedClient;
}

function cleanEmptyAssociations(clientCopy) {
	clientCopy.documents = clientCopy.documents.filter((val) => !!val.numero);
	clientCopy.emails = clientCopy.emails.filter((val) => !!val.email);
	clientCopy.phones = clientCopy.phones.filter((val) => !!val.numero);
	clientCopy.addresses = clientCopy.addresses.filter((val) => !!val.direccion || !!val.codigo_postal);
	clientCopy.bank_accounts = clientCopy.bank_accounts.filter((val) => !!val.numero);
	clientCopy.credit_cards = clientCopy.credit_cards.filter((val) => !!val.numero);
	clientCopy.contacts = clientCopy.contacts.filter((val) => !!val.nombre);

	return clientCopy;
}

function setSimpleAssociations(clientCopy) {
	clientCopy.employee_ids = clientCopy.employees.map((val) => val.id);
	clientCopy.referido_por_id = clientCopy.referido_por ? clientCopy.referido_por.id : null;
	clientCopy.segment_id = clientCopy.segment ? clientCopy.segment.id : null;
	clientCopy.sub_segment_id = clientCopy.sub_segment ? clientCopy.sub_segment.id : null;
	clientCopy.region_id = clientCopy.region ? clientCopy.region.id : null;
	return clientCopy;
}
