import { addEditFilterForm } from './polizas/addEditFilterForm';
import { bienes } from './polizas/bienes';
import { comisiones } from './polizas/comisiones';
import { pagos } from './polizas/pagos';

export const polizas = () => {
	return {
        extra_fields: {
            capital_asegurado: 'Capital asegurado',
            capital_maximo_por_persona: 'Capital máximo por persona',
            capital_minimo: 'Capital mínimo',
            capital_automático: 'Capital automático',
            capital_maximo: 'Capital máximo',
            capital_maximo_conyuge: 'Capital máximo cónyuge',
            capital_maximo_mayores: 'Capital máximo mayores',
            alicuota_variable: 'Alicuota variable',
        },
		masivas: {
			title: 'Acciones Masivas',
			items: {
				modificarRefacturacion: {
					label: 'Modificar Refacturación',
					confirmHeader: 'Modificar Refacturaciones',
					confirmMessage:
						'Se van a modificar los tipos de Refacturación de las {numero} pólizas del listado. Esta acción no se puede deshacer. ¿Continuar?',
					dialogHeader: 'Refacturación',
					dialogMessage: 'Modifica el tipo de Refacturacion sobre todas las pólizas del listado actual',
				},
				redefinirVigencia: {
					label: 'Redefinir Vigencia Hasta',
					confirmHeader: 'Redefinir Vigencias',
					confirmMessage:
						'A todas las pólizas se les asignará una Vigencia Hasta {numero} meses posterior a su Vigencia Desde. Revise bien la informacion, esta acción no se puede deshacer. ¿Continuar?',
					dialogHeader: 'Cantidad Total de Meses de Vigencia',
					dialogMessage:
						'Modifica la vigencia hasta de todas las pólizas del listado actual, sumando la cantidad de meses seleccionados a la vigencia desde',
				},
				reporteSolicitudes: {
					label: 'Reporte Solicitudes AP',
					confirmHeader: '',
					confirmMessage: '',
					dialogHeader: '',
					dialogMessage: '',
				},
			},
		},
		bienes: bienes(),
		pagos: pagos(),
		comisiones: comisiones(),
		addEditFilterForm: addEditFilterForm(),
        validate_prima_premio: 'La prima no puede ser mayor al premio',
        renovada_por: 'Renovada por póliza',
        renueva_a: 'Renovó a póliza',
        confirm_poliza: 'Confirmar póliza (bloquear)',
        unconfirm_poliza: 'Desbloquear póliza',
        unconfirm_tooltip: 'Desbloquea la póliza para edición de datos generales',
        confirm_tooltip: 'Bloquea la póliza para edición de datos generales',
        confirm_unconfirm: 'Desbloquear la póliza para poder volver a modificar datos generales. ¿Continuar?',
        confirm_confirm: 'Luego de confirmar la póliza, no podrá volver a modificar sus datos básicos. Sólo los usuarios con el permiso necesario podrán "desconfirmar" la póliza. ¿Continuar?',
        confirm_endoso: 'Confirmar endoso (bloquear)',
        unconfirm_endoso: 'Desbloquear endoso',
        confirm_endoso_tooltip: 'Bloquea el endoso para edición de datos generales',
        unconfirm_endoso_tooltip: 'Desbloquea el endoso para edición de datos generales',
        confirm_endoso_unconfirm: 'Desbloquear el endoso para volver a editar datos generales. ¿Continuar?',
        confirm_endoso_confirm: 'Luego de confirmar el endoso, no podrá volver a modificar sus datos básicos. Sólo los usuarios con el permiso necesario podrán "desconfirmarlo". ¿Continuar?',
        mapa_polizas: 'Mapa de pólizas',
        mapa_polizas_150: 'No se puede generar el mapa de pólizas para mas de 150 registros',
        estructura_comisional: 'Estructura comisional',
        renovacion_cargada: 'Renovación cargada exitosamente',
        confirm_unificar: 'Unificar pólizas. ¿Confirmar?',
        unificar_success: 'Pólizas unificadas',
        unificar_background: 'Se están unificando las Pólizas en paralelo. Este proceso puede demorar varios minutos.',
		confirm: {
			delete_endoso: '¿Eliminar endoso seleccionado? Esta acción no se puede deshacer.',
			delete: 'Se eliminará la póliza definitivamente del sistema. ¿Confirmar?',
			unificar_endoso: 'Unificar Endosos ¿Continuar?',
		},
		toast: {
			success: {
				summary: {
					endoso_unificado: 'Unificación exitosa.'
				},
				detail: {
					endoso_unificado: 'El endoso se ha unificado exitosamente.'
				},
			},
			error: {
				detail: {
					unificar: 'No se puede unificar al mismo endoso.'
				}
			},
		},
        endoso_eliminado: 'Endoso eliminado',
        endoso_frozen: 'Endoso confirmado (bloqueado) - {fecha}',
        codigo_pas: 'Código productor',
        no_renovable: 'No renovable',
		vigdesde: 'Vig. desde',
		vighasta: 'Vig. hasta',
		refacturacion: 'Refacturación',
		coaseguro: 'Coaseguro',
		coaseguro_piloto: 'Póliza piloto',
        coaseguro_total_invalido: 'El porcentaje total de coaseguros (incluyendo el de esta póliza) debe ser 100% y se ha cargado {msg} %. Revise los datos',
        coaseguro_disclaimer_1: 'En nueva póliza de coaseguro, deben ingresar en esta póliza (la principal), los importes TOTALES de primas, premio y suma asegurada, como así también en las coberturas.',
        coaseguro_disclaimer_2: 'Luego el sistema calcula los montos correspondientes a cada póliza en relación al porcentaje de participación de cada una.',
		hora: 'Hora',
		baja: 'Baja',
		media: 'Media',
		alta: 'Alta',
		costs: {
			recargo_financiero_final: 'Recargo financiero nominal',
			porcentaje_recargo_financiero: 'Rec. Fin. %',
			recargo_comercial_final: 'Recargo comercial nominal',
			porcentaje_recargo_comercial: 'Rec. Com. %',
			porcentaje_recargo_administrativo: 'Rec. Adm. %',
			recargo_administrativo_final: 'Recargo administrativo nominal',
			derecho_emision: 'Derecho de emisión',
			gastos_notoriales: 'Gastos notoriales',
			prima: 'Prima',
			prima_comisionable: 'Prima comisionable',
			premio: 'Premio',
            premio_consolidado: 'Premio (final)',
            prima_consolidada: 'Prima (final)',
            prima_comisionable_consolidada: 'Prima comisionable (final)',
			sa: 'SA',
			cuotas: 'Cuotas',
			iva_base: 'IVA Base %',
			iva_adicional: 'IVA Adicional (%)',
			tasa_super: 'Tasa SSN %',
			sellado: 'Sellado %',
			otros_impuestos: 'Otros impuestos (%)',
			iibb: 'IIBB %',
			obra_social: 'Obra social %',
            confirm_calc_prima: 'Se recalculará la prima de los bienes para calcular la prima comisionable. Las coberturas de los bienes deben incluir tasa x mil. ¿Confirmar?',
            calcular_prima_com: 'Calcular prima comisionable',
			calcular_premio_x_prima: 'Calcular premio por prima',
			calcular_premio_suma_bienes: 'Calcular premio (suma premio bienes)',
		},
		context: {
            attach_documents: 'Documentos oficiales adjuntos',
            documents: 'Documentos oficiales',
            unificar: 'Unificar póliza duplicada',
            descargar_docs: 'Descargar documentación',
            ver_coaseguro_piloto: 'Coaseguro - ver póliza piloto',
            ver_poliza_siguiente: 'Ver/Editar Póliza Siguiente (renovación)',
            ver_poliza_anterior: 'Ver/Editar Póliza Anterior',
            ver_bienes: 'Bienes consolidados',
            editar_asegurado: 'Ver/Editar Asegurado',
			cargar_siniestro: 'Cargar Siniestro',
			poliza_endoso: 'Póliza {numero} {msg}',
			poliza_propuesta: 'Propuesta {numero}',
			buscar_endoso: 'Buscar endoso',
            ver_endosos: 'Ver endosos',
			buscar_bien: 'Buscar bien asegurado',
			nuevo_endoso: 'Nuevo endoso',
			unificar_endoso: 'Unificar Endoso duplicado',
            eliminar: 'Eliminar del sistema',
            ver_seguimientos: 'Ver Seguimientos',
			crear_seguimiento: 'Nuevo Seguimiento',
			ver_siniestros: 'Ver Siniestros',
			header_show_siniestros: 'Siniestros de Poliza {num}',
		},
		form: {
			notas: {
				header: 'Notas',
				externa: 'Notas para la Compañía',
				interna: 'Notas Internas',
			},
            admin_from_admin: 'Gestionar desde Administrar - Tablas Internas',
            vigencia_abierta: 'Vigencia abierta',
            assign_employee: '{nombre} autoasignado a la póliza',
            poliza_eliminada: 'Póliza eliminada del sistema',
            add_coaseguro: 'Agregar Compañía / Propuesta de Coaseguro',
            coaseguro_porcentaje: 'Porcentaje Coaseguro en esta Póliza (Principal)',
			cantidad_cuotas_propuesta: 'Cant. cuotas propuesta',
			cantidad_cuotas_tooltip: 'Cantidad de cuotas propuesta a la compañía previo a la emisión',
			plan_pagos: 'Plan de Pagos / Comisiones',
			modificar_bienes: 'Modificar bienes previos',
			tipo_endoso: 'Tipo Endoso',
			premio_impuestos: 'Premio / Impuestos',
			primas_recargos: 'Primas / Recargos',
			bonificacion: 'Bonificación',
			costos_generales: 'Generales',
			costos_avanzado: 'Costos (avanzado)',
			complementarios: 'Datos complementarios',
			nueva: 'Nueva Propuesta / Póliza',
			datos_principales: 'Datos Principales',
			asegurados_tomadores: 'Asegurados / Tomadores',
			tooltips: {
				agregar_customer: 'Crear nuevo cliente y agregar a la póliza',
				edit_customer: 'Editar cliente seleccionado',
			},
			add_code: 'Agregar nuevo código en {cia} para {productor} y asignarlo a la póliza',
			coaseguro_piloto: 'Coaseguro (piloto)',
			servicios_extra: 'Servicios extra',
			tipo_poliza: 'Tipo póliza',
			origen: 'Orígen de la propuesta',
			tipo_renovacion: 'Tipo renovación',
			costos_basico: 'Costos (básico)',
			numero_endoso: 'Número de endoso',
			numero_poliza: 'Número de póliza',
			numero_propuesta: 'Número de propuesta',
			fecha_emision: 'Fecha de emisión',
			vigencia_desde: 'Vigencia desde',
			vigencia_hasta: 'Vigencia hasta',
			fecha_solicitud: 'Fecha de solicitud',
			fecha_envio_cliente: 'Fecha de envío al Cliente',
			fecha_recepcion: 'Fecha de recepción',
			principal: 'Datos principales',
			tipo_despacho: 'Forma despacho',
			oficina_programa_mundial: 'Oficina programa mundial',
			lineas_financieras: 'Línea financiera',
			pu: 'P.U.',
			cant_cuotas_poliza: 'Cantidad de cuotas de la póliza',
		},
		snapshot: {
            endoso_cargado_por_import: 'Endoso cargado vía interfaz',
            cargada_por_import: 'Cargada vía interfaz',
            cargado_por_import: 'Cargado vía interfaz',
			title: 'Pólizas / Propuestas',
            frozen: 'Póliza confirmada (bloqueada) - {fecha}',
			searchTitle: 'Búsqueda de Pólizas',
			endorsements: 'Endosos',
			endorsement: 'Endoso',
            documentos_poliza: 'Documentos oficiales de la póliza {num}',
            adjuntos_poliza: 'Adjuntos de la póliza {num}',
			nota_interna: 'Nota interna',
			nota_externa: 'Nota externa',
			ver_nota_interna: 'Ver nota interna',
			ver_nota_externa: 'Ver nota externa',
			seemoreinpolicy: '(ver más en Bienes consolidados)',
			attached_doc: 'Documento adjunto',
			filters: {
				solo_activas: 'Solo activas',
				principales: 'Principales',
				participantes: 'Participantes',
				otros: 'Otros filtros de búsqueda',
				ramos_coberturas: 'Ramos / Coberturas',
				person: 'Asegurado/Tomador',
				coveragesIn: 'Tiene cobertura de',
				coveragesOut: 'NO tiene cobertura de',
				ramosIn: 'Incluir pólizas de los Ramos',
				ramosOut: 'Excluir pólizas de los Ramos',
				emision_gte: 'Emisión posterior a',
				emision_lte: 'Emisión anterior a',
				vigencia_desde_gte: 'Inicio vigencia posterior a',
				vigencia_desde_lte: 'Inicio vigencia anterior a',
				vigencia_hasta_gte: 'Fin de vigencia posterior a',
				vigencia_hasta_lte: 'Fin de vigencia anterior a',
				refacturacion_gte: 'Refacturación posterior a',
				refacturacion_lte: 'Refacturación anterior a',
				envio_cliente_gte: 'Fec. envío al cliente posterior a',
				envio_cliente_lte: 'Fec. envío al cliente anterior a',
			},

			loadLazyData: {
				toastErrorDetail: 'Error buscando pólizas',
			},
			list: {
				headers: {
					poliza: 'Póliza / Propuesta',
					productor: 'Productor / Intermediarios',
				},
			},
			confirm: {
				message: 'La póliza seleccionada quedará en estado Renovada cuando guarde la nueva póliza. ¿Continuar?',
			},
		},
	};
};
