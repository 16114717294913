export const chatbot = () => {
	return {
		title: 'Administrar Chatbot',
		not_enabled: 'Chatbot no habilitado',
		not_enabled_detail: 'Comuníquese con Soporte para conocer la funcionalidad del Chatbot.',
		allowed_downloads: 'Descargas permitidas',
		allowed_downloads_copmany: 'Definir documentos habilitados para {company}',
		table_title: 'Definir documentos habilitados para descarga por compañía',
		definir_configuracion: 'Definir acciones ofrecidas por el chatbot',
        disponibles: 'Documentos disponibles',
        habilitados: 'Documentos habilitados',
        cargar_siniestros: 'Cargar siniestros',
        solicitar_asesoria: 'Solicitar asesoria',
        solicitar_baja: 'Solicitar baja de pólizas',
        pedir_documentacion: 'Descargar documentación',
        control_chatbot: 'Workflow por chatbot',
        control_ia: 'Control por IA',
        email_notificacion: 'Email para notificaciones de siniestros',
        descarga_doc_title: 'Descarga documentación - Definir documentos disponibles',
        history: {
            conversations: 'Últimas conversaciones',
            title: 'Chatbot - Historial de conversaciones',
            conversation: 'Conversación con {name}',
            no_messages: 'No hay mensajes en esta conversación',
        },
	};
};